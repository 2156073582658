import React from "react";
import Top from "../hooks/topping";

function NotFound() {
  return (
    <div className="bft-main put-margin">
      <div className="wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080">
          <g id="Layer_12 blue-back-fig" data-name="Layer 12">
            <path
              className="cls-1"
              d="M600.87,872H156a4,4,0,0,0-3.78,4.19h0a4,4,0,0,0,3.78,4.19H600.87a4,4,0,0,0,3.78-4.19h0A4,4,0,0,0,600.87,872Z"
            />
            <rect
              className="cls-1"
              x="680.91"
              y="871.98"
              width="513.38"
              height="8.39"
              rx="4.19"
              ry="4.19"
            />
            <path
              className="cls-1"
              d="M1480,876.17h0c0,2.32,2.37,4.19,5.3,4.19h350.61c2.93,0,5.3-1.88,5.3-4.19h0c0-2.32-2.37-4.19-5.3-4.19H1485.26C1482.33,872,1480,873.86,1480,876.17Z"
            />
            <rect
              className="cls-1"
              x="492.21"
              y="920.64"
              width="249.8"
              height="8.39"
              rx="4.19"
              ry="4.19"
            />
            <path
              className="cls-1"
              d="M1549.14,924.84h0a4.19,4.19,0,0,0-4.19-4.19H1067.46a14.66,14.66,0,0,1,.35,3.21v1A4.19,4.19,0,0,0,1072,929h472.94A4.19,4.19,0,0,0,1549.14,924.84Z"
            />
            <path
              className="cls-1"
              d="M865.5,924.84h0a4.19,4.19,0,0,0,4.19,4.19h82.37a12.28,12.28,0,0,1-.19-2v-2.17a4.19,4.19,0,0,0-4.19-4.19h-78A4.19,4.19,0,0,0,865.5,924.84Z"
            />
            <rect
              className="cls-1"
              x="915.6"
              y="981.47"
              width="54.72"
              height="8.39"
              rx="4.19"
              ry="4.19"
            />
            <path
              className="cls-1"
              d="M730.33,985.67h0c0,2.32,4.23,4.19,9.44,4.19h104.3c5.22,0,9.44-1.88,9.44-4.19h0c0-2.32-4.23-4.19-9.44-4.19H739.78C734.56,981.47,730.33,983.35,730.33,985.67Z"
            />
            <rect
              className="cls-1"
              x="997.06"
              y="981.47"
              width="78.11"
              height="8.39"
              rx="4.19"
              ry="4.19"
            />

            <g id="round-conf">
              <path
                className="cls-1 circle c1"
                d="M536.41,155.14a17.77,17.77,0,1,0,17.77,17.77A17.77,17.77,0,0,0,536.41,155.14Zm0,28.68a10.9,10.9,0,1,1,10.9-10.9A10.9,10.9,0,0,1,536.41,183.81Z"
              />
              <path
                className="cls-1 circle c2"
                d="M1345.09,82.44a17.77,17.77,0,1,0,17.77,17.77A17.77,17.77,0,0,0,1345.09,82.44Zm0,28.68a10.9,10.9,0,1,1,10.9-10.9A10.9,10.9,0,0,1,1345.09,111.12Z"
              />
              <path
                className="cls-1 circle c3"
                d="M70.12,363A17.77,17.77,0,1,0,87.89,380.8,17.77,17.77,0,0,0,70.12,363Zm0,28.68A10.9,10.9,0,1,1,81,380.8,10.9,10.9,0,0,1,70.12,391.7Z"
              />
              <path
                className="cls-1 circle c4"
                d="M170.47,751.82a17.77,17.77,0,1,0,17.77,17.77A17.77,17.77,0,0,0,170.47,751.82Zm0,28.68a10.9,10.9,0,1,1,10.9-10.9A10.9,10.9,0,0,1,170.47,780.5Z"
              />
              <path
                className="cls-1 circle c5"
                d="M1457.34,762.73a17.77,17.77,0,1,0,17.77,17.77A17.77,17.77,0,0,0,1457.34,762.73Zm0,28.68a10.9,10.9,0,1,1,10.9-10.9A10.9,10.9,0,0,1,1457.34,791.4Z"
              />
              <path
                className="cls-1 circle c6"
                d="M1829.15,407.49a17.77,17.77,0,1,0,17.77,17.77A17.77,17.77,0,0,0,1829.15,407.49Zm0,28.68a10.9,10.9,0,1,1,10.9-10.9A10.9,10.9,0,0,1,1829.15,436.17Z"
              />
            </g>
          </g>
          <g id="fortyfour" data-name="Layer 2">
            <g className="four a">
              <rect
                className="cls-2"
                x="233.74"
                y="391.14"
                width="120.71"
                height="466.29"
                rx="57.1"
                ry="57.1"
                transform="translate(918.39 330.19) rotate(90)"
              />

              <rect
                className="cls-3"
                x="333.83"
                y="475.1"
                width="120.71"
                height="396.88"
                rx="60.36"
                ry="60.36"
              />

              <rect
                className="cls-3"
                x="197.13"
                y="122.89"
                width="120.71"
                height="604.75"
                rx="60.36"
                ry="60.36"
                transform="translate(290.49 -70.78) rotate(35)"
              />
            </g>
            <g className="four b">
              <rect
                className="cls-2"
                x="1558.84"
                y="391.91"
                width="120.71"
                height="466.29"
                rx="57.1"
                ry="57.1"
                transform="translate(2244.26 -994.14) rotate(90)"
              />

              <rect
                className="cls-3"
                x="1658.92"
                y="475.87"
                width="120.71"
                height="396.88"
                rx="60.36"
                ry="60.36"
              />

              <rect
                className="cls-3"
                x="1522.22"
                y="123.66"
                width="120.71"
                height="604.75"
                rx="60.36"
                ry="60.36"
                transform="translate(530.57 -830.68) rotate(35)"
              />
            </g>
            <path
              className="cls-3"
              id="ou"
              d="M956.54,168.2c-194.34,0-351.89,157.55-351.89,351.89S762.19,872,956.54,872s351.89-157.55,351.89-351.89S1150.88,168.2,956.54,168.2Zm0,584.49c-128.46,0-232.6-104.14-232.6-232.6s104.14-232.6,232.6-232.6,232.6,104.14,232.6,232.6S1085,752.69,956.54,752.69Z"
            />
          </g>
        </svg>
      </div>
      <Top />
    </div>
  );
}

export default NotFound;
