import React from "react";
import Popup from "reactjs-popup";

const ModalPopup = ({ children }) => {
  return (
    <Popup
      modal={true}
      trigger={
        <i className="fa fa-edit pointer bft-right bft-large bft-padding"></i>
      }
      position="center"
    >
      {children}
    </Popup>
  );
};

export default ModalPopup;
