import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
const AssetCart = ({ asset }) => {
  // const [fileType, setFileType] = useState();
  // useEffect(async () => {
  //   try {
  //     const toDataURL = async (url) => {
  //       fetch(url)
  //         .then((response) => response.blob())
  //         .then((blob) => {
  //           setFileType(blob.type.split("/")[0]);
  //         });
  //     };
  //     toDataURL(asset?.metadata?.image);
  //   } catch (err) {
  //     console.log("err file error", err);
  //   }
  // }, [asset]);
  return (
    <div className="bft-third" key={asset?.tokenId || "as"}>
      <div
        className="bft-card"
        style={{
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        <Link
          to={`/assets/${asset?.tokenAddress}/${asset?.tokenId}`}
          title={asset?.metadata?.name}
          style={{ textDecoration: "none" }}
          className="link-dark"
        >
          <div
            className="flip-box"
            style={{
              width: "100%",
              height: "150px",
            }}
          >
            <div className="flip-box-inner">
              <div className="flip-box-front">
                {asset.metadata.mediaType === "image" && (
                  <img
                    src={asset?.metadata?.image}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                )}
                {asset.metadata.mediaType === "audio" && (
                  <img
                    src={"/audio.gif"}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                )}
                {asset.metadata.mediaType === "pdf" && (
                  <img
                    src={"/pdf.png"}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                )}
                {asset.metadata.mediaType === "video" && (
                  <video
                    style={{ width: "100%", height: "100%" }}
                    // onMouseOver={(event) => event.target.play()}
                    // onMouseOut={(event) => event.target.pause()}
                    src={asset?.metadata?.image}
                  ></video>
                )}
                {asset.metadata.mediaType !== "video" ||
                  asset.metadata.mediaType !== "pdf" ||
                  asset.metadata.mediaType !== "image" ||
                  (asset.metadata.mediaType !== "audio" && (
                    <img
                      src={"https://i.imgur.com/Us5ckqm.jpg"}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ))}
                {/* <div
                    style={{
                      backgroundImage: "url(" + asset.metadata.image + ")",
                      height: "150px",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                      backgroundSize: "100% auto",
                    }}
                  ></div> */}
              </div>
              <div className="flip-box-back">
                <p className="bft-padding">{asset?.metadata?.name}</p>
              </div>
            </div>
          </div>
          <button className="bft-btn bft-sand" style={{ width: "100%" }}>
            {asset.metadata.name}
            {/* VIEW NFT ASSET */}
          </button>
        </Link>
      </div>
      <br />
    </div>
  );
};

export default AssetCart;
