import axios from "axios";

export default axios.create({
  baseURL: "https://app.nzvedazbigfive.io",
  headers: {
    "Content-type": "multipart/form-data",
    timeout: 1000,
    Authorization: "Bearer uurt6y-u6y7ujHopY_twgTre354ghsrH",
  },
});
