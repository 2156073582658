import React, { useEffect, useState } from "react";
import { injected } from "./connector";
import { useWeb3React } from "@web3-react/core";
import Loader from "../web/loader";

function MetamaskProvider(_a) {
  var children = _a.children;
  var _b = useWeb3React(),
    networkActive = _b.active,
    networkError = _b.error,
    activateNetwork = _b.activate;
  var _c = useState(false),
    loaded = _c[0],
    setLoaded = _c[1];
  useEffect(
    function () {
      injected
        .isAuthorized()
        .then(function (isAuthorized) {
          setLoaded(true);
          if (isAuthorized && !networkActive && !networkError) {
            activateNetwork(injected);
          }
        })
        .catch(function () {
          setLoaded(true);
        });
    },
    [activateNetwork, networkActive, networkError]
  );
  if (loaded) {
    return children;
  }
  return (
    <div className="bft-center">
      <Loader />
    </div>
  );
}

export default MetamaskProvider;
