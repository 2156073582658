import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useWeb3React } from "@web3-react/core";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { Link, useParams } from "react-router-dom";
import Wallet from "../sections/wallet";
import API from "../../jsx/backend";
import Loader from "../loader";
import Top from "../hooks/topping";
import { getEllips } from "../helpers/common";
import { useContract } from "../helpers/use-connector";

import axios from "../hooks/axiosInstance";
import AssetCart from "../components/AssetCart";
const Assets = () => {
  const { account, active } = useWeb3React();
  const { address } = useParams();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [assets, setAssets] = useState([]);
  const { mediaContract } = useContract();
  // ------------old work-----------
  // useEffect(() => {
  //   if (typeof account !== "undefined") {
  //     setLoading(true);
  //     API.get(`api/session/${account}/`)
  //       .then((response) => {
  //         sessionStorage.setItem("secret", response.data.secret);
  //         setLoading(false);
  //         API.get(
  //           `api/${account}/${sessionStorage.getItem("secret")}/account/assets/`
  //         )
  //           .then((res) => {
  //             const assetsList = res.data;
  //             setAssets(assetsList);
  //             setFetching(false);
  //           })
  //           .catch((error) => {
  //             setError(true);
  //           });
  //       })
  //       .catch((error) => {
  //         setError(true);
  //         setLoading(false);
  //       });
  //   }
  // }, [account]);
  // ------------new work-----------
  // useEffect(() => {
  //   let { ethereum } = window;
  //   const web3Provider = new Web3(ethereum);
  //   let contract = new web3Provider.eth.Contract(contractABI, contractAddress);
  //   setContract(contract);
  // }, []);

  useEffect(async () => {
    if (typeof address !== "undefined") {
      try {
        setLoading(true);
        // const res = await mediaContract.methods
        //   .totalOwnedTokens(address)
        //   .call();
        const res = await mediaContract.methods
          .totalListedAndUnListed(address)
          .call();

        if (res?.length > 0) {
          const getingNFT = await axios.post("/api/getnfts", {
            tokenIds: res,
          });
          if (getingNFT?.status === 200) {
            setAssets(getingNFT.data.nfts);
            setFetching(false);
            setLoading(false);
          }
          setFetching(false);
          setLoading(false);
        } else {
          setFetching(false);
          setLoading(false);
        }
      } catch (err) {
        setFetching(false);
        setLoading(false);
        console.log("[err ]", err);
        // setError(true);
      }
    }
  }, [address, mediaContract]);

  return (
    <div className="bft-main put-margin inner">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Manage NFT Assets - Nzvedaz BFT Marketplace</title>
      </Helmet>
      {active ? (
        <div className="bft-section">
          {!loading ? (
            <>
              {!error ? (
                <div className="bft-container">
                  <h4>
                    Manage Assets
                    <i className="fa fa-list bft-right bft-large"></i>
                  </h4>
                  <hr />
                  {fetching ? (
                    <div className="bft-content bft-center">
                      <h1>Fetching your assets...</h1>
                      <Loader />
                    </div>
                  ) : (
                    <div className="bft-row-padding">
                      {assets?.length > 0 ? (
                        assets.map((asset) => <AssetCart asset={asset} />)
                      ) : (
                        <h1 className="bft-center">You have no assets!</h1>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div className="bft-content bft-center">
                  <h1>Something went wrong, please check back later!</h1>
                  <img
                    src="/default/error.png"
                    alt="Error!"
                    style={{ height: "150px", width: "150px" }}
                  />
                </div>
              )}
            </>
          ) : (
            <div className="bft-container bft-content bft-center">
              <h1>Checking account information...</h1>
              <Loader />
            </div>
          )}
        </div>
      ) : (
        <div className="bft-section">
          <div className="bft-container">
            <div className="bft-content bft-center">
              <h1>
                You must be logged in with your wallet to access this page!
              </h1>
              <Popup
                modal={true}
                trigger={
                  <button className="bft-btn bft-green bft padding-large">
                    CONNECT YOUR WALLET
                  </button>
                }
                position="center"
              >
                <Wallet />
              </Popup>
            </div>
          </div>
        </div>
      )}
      <Top />
    </div>
  );
};

export default Assets;
