import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
//import { useWeb3React } from '@web3-react/core';
import "reactjs-popup/dist/index.css";
import { Link } from "react-router-dom";
import API from "../../jsx/backend";
import Loader from "../loader";
import Top from "../hooks/topping";
import InputHints from "react-input-hints";
import axios from "../hooks/axiosInstance";
import AssetCart from "../components/AssetCart";
import Pagination from "react-bootstrap-4-pagination";

const Shop = () => {
  //const { account, active } = useWeb3React();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [phrase, setPhrase] = useState("");
  const [title, setTitle] = useState("Please Wait");
  const [assets, setAssets] = useState("");
  const [results, setResults] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const fetchNFT = async (mopFunc) => {
    try {
      mopFunc(true);
      setTitle("shop");
      const res = await axios.get(
        `/api/getmarketassets?page=${page - 1}&limit=10`
      );
      if (res?.data?.nfts?.length > 0) {
        // let isOnSellAssets = res?.data?.nfts?.filter(function (e) {
        //   return e.isOnSell === true;
        // });
        setTotalPages(res?.data?.totalPage);
        setAssets(res.data.nfts);
      }
      mopFunc(false);
    } catch (err) {
      setTitle("Not Responding");
      if (!err?.response?.data) {
        alert("work");
        setError(true);
      }
      mopFunc(false);
      setAssets("");

      console.log("err [fething all asset]");
    }
  };
  useEffect(async () => {
    fetchNFT(setLoading);
  }, []);
  useEffect(async () => {
    fetchNFT(setProcessing);
  }, [page]);

  const forPhrase = (e) => {
    setPhrase(e.target.value);
  };

  const handleSearch = async (event) => {
    event.preventDefault();

    try {
      setTitle("Shop");
      setProcessing(true);
      const res = await axios.get(
        `/api/searchasset/?searchquery=${phrase}&page=${page - 1}&limit=10`
      );
      if (res?.data?.nfts?.length > 0) {
        setAssets(res.data.nfts);
        setResults(true);
      }
      setProcessing(false);
    } catch (err) {
      setProcessing(false);
      console.log("err [fething all asset]", err);
    }
  };
  const handleReset = (event) => {
    event.preventDefault();
    setPage(1);
    setResults(false);
    fetchNFT(setProcessing);
  };

  return (
    <div className="bft-main put-margin inner">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title} - Nzvedaz BFT Marketplace</title>
      </Helmet>
      <div className="bft-section">
        {!loading ? (
          <>
            {!error ? (
              <div className="bft-container">
                <div className="bft-section bft-content">
                  <div style={{ paddingTop: "20px" }}></div>
                  <form
                    className="bft-white bft-container bft-padding bft-border bft-border-grey"
                    style={{ borderRadius: "10px" }}
                    onSubmit={handleSearch}
                  >
                    <div className="bft-col s7">
                      <InputHints
                        type="text"
                        value={phrase}
                        onChange={forPhrase}
                        className="bft-input bft-border-0"
                        style={{
                          borderRadius: "10px 0 0 10px",
                          width: "100%",
                          outline: "none",
                        }}
                        placeholders={[
                          "Type search phrase...",
                          "African heritage...",
                          "Deep sea...",
                          "Arbitrary...",
                        ]}
                        required
                      />
                    </div>
                    <div className="bft-col s5">
                      {results ? (
                        <button
                          type="button"
                          onClick={handleReset}
                          className="bft-btn bft-input bft-brown bft-border-0"
                          style={{ borderRadius: "10px", width: "100%" }}
                        >
                          <i className="fas fa-times"></i>{" "}
                          <span className="bft-hide-small">RESET FILTER</span>
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="bft-btn bft-input bft-color bft-border-0"
                          style={{ borderRadius: "10px", width: "100%" }}
                        >
                          <i className="fas fa-search"></i>{" "}
                          <span className="bft-hide-small">SEARCH ASSETS</span>
                        </button>
                      )}
                    </div>
                  </form>
                  <div style={{ paddingTop: "20px" }}></div>
                </div>
                {processing ? (
                  <div className="bft-content bft-center">
                    <Loader />
                  </div>
                ) : (
                  <>
                    <div className="bft-row-padding">
                      {assets ? (
                        <>
                          {assets.map((asset) => (
                            <AssetCart asset={asset} buttonTitle="VIEW NFT" />
                          ))}
                        </>
                      ) : (
                        <h1 className="bft-center">No Assets Found!</h1>
                      )}
                    </div>
                    {totalPages > 1 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "10px 0",
                        }}
                      >
                        <Pagination
                          threeDots
                          onClick={(e) => {
                            setPage(e);
                          }}
                          totalPages={totalPages}
                          currentPage={page}
                          showMax={5}
                          prevNext
                          activeBgColor="#e36404"
                          activeBorderColor="#b8b8b8"
                          color="#000000"
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            ) : (
              <div className="bft-content bft-center">
                <h1>Something went wrong, please check back later!</h1>
                <img
                  src="/default/error.png"
                  alt="Error!"
                  style={{ height: "150px", width: "150px" }}
                />
              </div>
            )}
          </>
        ) : (
          <Loader />
        )}
      </div>
      <Top />
    </div>
  );
};

export default Shop;
