import React from "react";
import ReactDOM from "react-dom";
import "./css/style.css";
import "./css/manipulate.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap/dist/css/bootstrap.min.css";

import App from "./jsx/app";
import reportWebVitals from "./jsx/reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
reportWebVitals();

// package.json start script
// "start": "HTTPS=true SSL_CRT_FILE=/etc/letsencrypt/live/app.thebigfivetoken.com/fullchain.pem SSL_KEY_FILE=/etc/letsencrypt/live/app.thebigfivetoken.com/privkey.pem react-scripts start",
