import React from "react";
import { useWeb3React } from "@web3-react/core";
import { injected, walletconnect } from "../../jsx/connector";
import MetaMask from "../../img/metamask.jpg";
import WalletConnect from "../../img/walletconnect.jpg";
import Swal from "sweetalert2";
const Wallet = () => {
  const { activate, active, error } = useWeb3React();

  function doMetaMask(e) {
    e.preventDefault();
    activate(injected);
    if (error) {
      console.log("error", error);
      Swal.fire({
        icon: "error",
        title: "Failed!",
        text: error?.message ? error?.message : error,
        footer:
          '<a href="https://help.nzvedazbigfive.io" className="link-dark">Why do I have this issue?</a>',
      });
    }
  }
  function doWalletConnect(e) {
    e.preventDefault();
    activate(walletconnect);
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Failed!",
        text: error,
        footer:
          '<a href="https://help.nzvedazbigfive.io" className="link-dark">Why do I have this issue?</a>',
      });
    }
  }

  return (
    <div className="bft-section">
      {!active ? (
        <>
          <div className="bft-container bft-content">
            <div className="bft-card bft-padding bft-border">
              <div onClick={doMetaMask}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={MetaMask}
                    alt="MetaMask"
                    style={{
                      width: "100px",
                      height: "auto",
                      cursor: "pointer",
                      padding: "10px",
                    }}
                  />
                  <p>
                    Use this to connect your browser extension wallet like
                    MetaMask Wallet or from Dapp browser while using Nzvedaz BFT
                    Marketplace.
                  </p>
                </div>
                <button
                  className="bft-btn bft-orange"
                  style={{ width: "100%" }}
                >
                  USE METAMASK
                </button>
              </div>
            </div>
          </div>
          <br />
          <div className="bft-container bft-content">
            <div className="bft-card bft-padding bft-border">
              <div onClick={doWalletConnect}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    <img
                      src={WalletConnect}
                      alt="Wallet Connect"
                      style={{
                        width: "100px",
                        height: "auto",
                        cursor: "pointer",
                        padding: "10px",
                      }}
                    />
                  </div>
                  <p>
                    Use this to connect your mobile wallet like Trust Wallet to
                    your desktop browser while using Nzvedaz BFT Marketplace.
                  </p>
                </div>
                <button className="bft-btn bft-blue" style={{ width: "100%" }}>
                  WALLET CONNECT
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <br />
          <h1 className="bft-center">YOU'RE ALREADY CONNECTED!</h1>
        </>
      )}
    </div>
  );
};

export default Wallet;
