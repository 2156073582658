import { useState, useEffect, useCallback } from "react";
import axios from "../hooks/axiosInstance";
import { useContract } from "./use-connector";

const useGetBid = ({ tokenId }) => {
  const [bids, setBids] = useState([]);
  const { marketContract } = useContract();
  const getingBids = useCallback(async () => {
    if (marketContract) {
      try {
        const res = await marketContract.methods.getBidsOnToken(tokenId).call();

        var arr = [];
        if (res?.length > 0) {
          Promise.all(
            res.map(async (element) => {
              const obj = Object.assign({}, element);
              try {
                const resOwner = await axios.get(
                  `/api/signin?address=${obj?.bidder}`
                );

                obj.user = resOwner.data;
                return obj;
              } catch (err) {
                const obj = Object.assign({}, element);
                return obj;
              }
            })
          ).then((data) => {
            setBids(data);
          });
        }
        setBids(arr);
      } catch (err) {
        console.log("err [fetching marketContract error]", err);
      }
    }
  }, [tokenId, marketContract]);
  useEffect(() => {
    getingBids();
  }, [getingBids, tokenId]);

  return { bids };
};

export { useGetBid };
