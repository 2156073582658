import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import API from "../../jsx/backend";
import Loader from "../loader";
import Top from "../hooks/topping";
import InputHints from "react-input-hints";
import Bg from "../../img/hero.jpg";
import { useWeb3React } from "@web3-react/core";
import axios from "../hooks/axiosInstance";
import AssetCart from "../components/AssetCart";
const Home = () => {
  const { account, active } = useWeb3React();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [phrase, setPhrase] = useState("");
  const [title, setTitle] = useState("Home");
  const [assets, setAssets] = useState("");
  const [results, setResults] = useState(false);
  React.useEffect(() => {
    if (account) {
      try {
        const acc = account?.toLowerCase();
        axios.post("/api/signup", {
          name: `user-${(Math.random() * 1000).toFixed()}`,
          address: acc,
        });
      } catch (err) {
        console.log("err", err);
      }
    }
  }, [account]);
  const fetchNFT = async (mopfunc) => {
    try {
      setTitle("Home");
      mopfunc(true);
      const res = await axios.get("/api/getmarketassets?page=0&limit=20");
      if (res?.data?.nfts?.length > 0) {
        // let isOnSellAssets = res?.data?.nfts?.filter(function (e) {
        //   return e.isOnSell === true;
        // });
        setAssets(res.data.nfts);
      }
      mopfunc(false);
    } catch (err) {
      mopfunc(false);
      console.log("err [fething all asset]", err);
    }
  };
  useEffect(() => {
    fetchNFT(setLoading);
    // API.get(`api/pages/`)
    //   .then((res) => {
    //     setTitle(res.data.title);
    //     API.get(`api/assets/auctions/`)
    //       .then((res) => {
    //         setAssets(res.data.assets);
    //         setLoading(false);
    //       })
    //       .catch((error) => {
    //         setAssets("");
    //         setLoading(false);
    //         setError(true);
    //       });
    //   })
    //   .catch((error) => {
    //     setTitle("Not Responding");
    //     setLoading(false);
    //     setError(true);
    //   });
  }, []);

  const forPhrase = (e) => {
    setPhrase(e.target.value);
  };

  const handleSearch = async (event) => {
    event.preventDefault();

    try {
      setTitle("Home");
      setProcessing(true);

      const res = await axios.get(
        `/api/searchasset/?searchquery=${phrase}&page=0&limit=10`
      );
      if (res?.data?.nfts?.length > 0) {
        setAssets(res.data.nfts);
        setResults(true);
      }
      setProcessing(false);
    } catch (err) {
      setProcessing(false);
      console.log("err [fething all asset]", err);
    }
    // setProcessing(true);
    // setError(false);
    // const searchFormDataNew = new FormData();
    // searchFormDataNew.append("phrase", phrase);
    // API.post(`api/assets/search/`, searchFormDataNew)
    //   .then((response) => {
    //     setAssets(response.data.assets);
    //     setResults(true);
    //     setProcessing(false);
    //   })
    //   .catch((error) => {
    //     setError(true);
    //     setProcessing(false);
    //   });
  };

  const handleReset = (event) => {
    event.preventDefault();

    setResults(false);
    fetchNFT(setProcessing);
  };

  return (
    <div className="bft-main put-margin inner">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title} - Nzvedaz BFT Marketplace</title>
      </Helmet>
      {!loading ? (
        <>
          <div
            style={{
              backgroundImage: "url(" + Bg + ")",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "100% 100%",
            }}
          >
            <div
              className="bft-padding"
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.35)",
                minHeight: "350px",
              }}
            >
              <h1 className="bft-center">CREATE, BUY & SELL NFT ASSETS</h1>
              <p className="bft-center">
                Nzvedaz BFT is the first African Decentralised NFT Marketplace.
              </p>
              <div style={{ paddingTop: "60px" }}></div>
              <div className="bft-section bft-container bft-content">
                <div style={{ paddingTop: "20px" }}></div>
                <form
                  className="bft-white bft-container bft-padding bft-border bft-border-grey"
                  style={{ borderRadius: "10px" }}
                  onSubmit={handleSearch}
                >
                  <div className="bft-col s7">
                    <InputHints
                      type="text"
                      value={phrase}
                      onChange={forPhrase}
                      className="bft-input bft-border-0"
                      style={{
                        borderRadius: "10px 0 0 10px",
                        width: "100%",
                        outline: "none",
                      }}
                      placeholders={[
                        "Type search phrase...",
                        "African heritage...",
                        "Deep sea...",
                        "Arbitrary...",
                      ]}
                      required
                    />
                  </div>
                  <div className="bft-col s5">
                    {results ? (
                      <button
                        type="button"
                        onClick={handleReset}
                        className="bft-btn bft-input bft-brown bft-border-0"
                        style={{ borderRadius: "10px", width: "100%" }}
                      >
                        <i className="fas fa-times"></i>{" "}
                        <span className="bft-hide-small">RESET FILTER</span>
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="bft-btn bft-input bft-color bft-border-0"
                        style={{ borderRadius: "10px", width: "100%" }}
                      >
                        <i className="fas fa-search"></i>{" "}
                        <span className="bft-hide-small">SEARCH ASSETS</span>
                      </button>
                    )}
                  </div>
                </form>
                <div style={{ paddingTop: "20px" }}></div>
              </div>
              <div style={{ paddingTop: "20px" }}></div>
            </div>
          </div>
          <br />
          <div className="bft-section">
            {!error ? (
              <div className="bft-container">
                {processing ? (
                  <div className="bft-content bft-center">
                    <h1>Fetching assets...</h1>
                    <Loader />
                  </div>
                ) : (
                  <div className="bft-row-padding">
                    {error ? (
                      <div className="bft-content bft-center">
                        <h1>Something went wrong, please check back later!</h1>
                        <img
                          src="/default/error.png"
                          alt="Error!"
                          style={{ height: "150px", width: "150px" }}
                        />
                      </div>
                    ) : (
                      <>
                        {assets ? (
                          <>
                            {assets.map((asset) => (
                              <AssetCart asset={asset} />
                            ))}
                          </>
                        ) : (
                          <h1 className="bft-center">We have no assets!</h1>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className="bft-content bft-center">
                <h1>Something went wrong, please check back later!</h1>
                <img
                  src="/default/error.png"
                  alt="Error!"
                  style={{ height: "150px", width: "150px" }}
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <Loader />
      )}
      <Top />
    </div>
  );
};

export default Home;
