import { useWeb3React } from "@web3-react/core";
import toast from "react-hot-toast";
import React, { useState } from "react";
import Popup from "reactjs-popup";
import { useContract } from "../../helpers/use-connector";
import Loader from "../../loader";
import axios from "../../hooks/axiosInstance";
import { useParams } from "react-router-dom";

const AcceptBid = ({ getAssetData, bidder }) => {
  const [loading, setLoading] = useState(false);
  const { account } = useWeb3React();
  const { tokenId } = useParams();
  const { mediaContract } = useContract();

  const handlePublishingAsset = async (close) => {
    if (account && mediaContract) {
      try {
        setLoading(true);
        const res = await mediaContract.methods
          .acceptBid(tokenId, bidder)
          .send({ from: account });
        if (res?.status) {
            const res = await axios.post("/api/nft", {
            tokenId,
            price: "0",
            isOnSell: false,
          });
          toast.success("Bid Accepted Successfully.");
        }
        getAssetData();
        setLoading(false);
        close();
      } catch (err) {
        if (err?.message) {
          toast.error(err.message);
          setLoading(false);
          close();
        } else {
          toast.error("something went wrong");
          setLoading(false);
          close();
        }
      }
    }
  };

  return (
    <Popup
      modal={true}
      trigger={
        <button
          className="bft-btn bft-color"
          style={{ flexGrow: 1, color: "#fff" }}
        >
          Accept
        </button>
      }
      position="center"
    >
      {(close) => (
        <div className="bft-content">
          {!loading ? (
            <>
              <h4>
                Are you sure to Accept Bid
                <i
                  className="fa fa-times pointer bft-right bft-large bft-text-red"
                  onClick={close}
                ></i>
              </h4>
              <hr />
              <div>
                <button
                  className="bft-input bft-btn bft-color"
                  style={{ marginTop: "15px" }}
                  onClick={() => {
                    handlePublishingAsset(close);
                  }}
                >
                  Accept Bid
                </button>
              </div>
            </>
          ) : (
            <div className="bft-center">
              <h1>Waiting for Transaction...</h1>
              <Loader />
            </div>
          )}
        </div>
      )}
    </Popup>
  );
};

export default AcceptBid;
