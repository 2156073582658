import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useWeb3React } from "@web3-react/core";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { Link, useParams } from "react-router-dom";
import Wallet from "../sections/wallet";
import API from "../../jsx/backend";
import Loader from "../loader";
import Top from "../hooks/topping";
import axios from "../hooks/axiosInstance";
import ModalPopup from "../components/ModalPopup";

const Account = () => {
  const { account, active } = useWeb3React();
  const { account: userAccount } = useParams();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [avatar, setAvatar] = useState("");
  const [cover, setCover] = useState("");
  const [name, setName] = useState("");
  const [followers, setFollowers] = useState(0);
  const [following, setFollowing] = useState(0);
  const [about, setAbout] = useState("");
  const [successname, setSuccessName] = useState("");
  const [successabout, setSuccessAbout] = useState("");
  const [newname, setNewname] = useState("");
  const [newabout, setNewabout] = useState("");
  const [successavatar, setSuccessAvatar] = useState("");
  const [newavatar, setNewavatar] = useState(null);
  const [successcover, setSuccessCover] = useState("");
  const [newcover, setNewcover] = useState(null);
  const [title, setTitle] = useState("Please Wait");

  React.useEffect(async () => {
    if (userAccount) {
      try {
        const acc = userAccount?.toLowerCase();
        const res = await axios.post("/api/signup", {
          name: `user-${(Math.random() * 1000).toFixed()}`,
          address: acc,
        });
      } catch (err) {
        console.log("err", err?.response);
      }
    }
  }, [userAccount]);
  useEffect(() => {
    if (userAccount) {
      setLoading(true);

      axios
        .get(`/api/signin?address=${userAccount}`)
        .then((res) => {
          const details = res.data;
          setAvatar(details.avatar);
          setCover(details.cover);
          setTitle(details.name);

          setName(details.name);
          setAbout(details.bio);

          setLoading(false);
        })
        .catch((error) => {
          setTitle("Not Responding");
          setLoading(false);
          setError(true);
        });

      API.get(`api/pages/account/`)
        .then((res) => {
          // setTitle(res.data.title);
          if (typeof userAccount !== "undefined") {
            API.get(`api/session/${userAccount}/`)
              .then((response) => {
                sessionStorage.setItem("secret", response.data.secret);
                API.get(`api/session/${userAccount}/${response.data.secret}/`)
                  .then((res) => {
                    const details = res.data;
                    // setAvatar(details.avatar);
                    // setCover(details.cover);
                    // setName(details.name);
                    // setAbout(details.bio);
                    setFollowing(details.following);
                    setFollowers(details.followers);
                    setLoading(false);
                  })
                  .catch((error) => {
                    setTitle("Not Responding");
                    setLoading(false);
                    setError(true);
                  });
              })
              .catch((error) => {
                setTitle("Not Responding");
                setLoading(false);
                setError(true);
              });
          }
        })
        .catch((error) => {
          setTitle("Not Responding");
          setLoading(false);
          setError(true);
        });
    }
  }, [userAccount]);

  const handleName = async (event, close) => {
    event.preventDefault();
    setLoadingRequest(true);

    axios
      .post("/api/updateuser", {
        address: userAccount.toLowerCase(),
        name: newname,
      })
      .then((response) => {
        axios
          .get(`/api/signin?address=${userAccount}`)
          .then((useer) => {
            setName(useer.data.name);
          })
          .catch((err) => {
            console.log("[err handle name]", err?.response, "----", err);
          });

        setSuccessName("Name has been updated succcessfully!");
        setLoadingRequest(false);
        close();
      })
      .catch((error) => {
        setError(true);
        setLoadingRequest(false);
        close();
      });
    const nftFormDataName = new FormData();
    nftFormDataName.append("name", newname);

    // API.post(
    //   `api/${account}/${sessionStorage.getItem("secret")}/user/modify/name/`,
    //   nftFormDataName
    // )
    //   .then((response) => {
    //     // setName(response.data.name);
    //     // setSuccessName("Name has been updated succcessfully!");
    //     setLoadingRequest(false);
    //     close();
    //   })
    //   .catch((error) => {
    //     setError(true);
    //     setLoadingRequest(false);
    //     close();
    //   });
  };

  const handleAbout = (event, close) => {
    event.preventDefault();
    setLoadingRequest(true);

    axios
      .post("/api/updateuser", {
        address: userAccount.toLowerCase(),
        bio: newabout,
      })
      .then((response) => {
        axios
          .get(`/api/signin?address=${userAccount}`)
          .then((useer) => {
            setAbout(useer.data.bio);
          })
          .catch((err) => {
            console.log("[err handle name]", err?.response, "----", err);
          });

        setLoadingRequest(false);
        setSuccessAbout("Bio has been updated succcessfully!");
        close();
      })
      .catch((error) => {
        setLoadingRequest(false);
        setError(true);
      });
    // const nftFormDataAbout = new FormData();
    // nftFormDataAbout.append("bio", newabout);
    // API.post(
    //   `api/${account}/${sessionStorage.getItem("secret")}/user/modify/bio/`,
    //   nftFormDataAbout
    // )
    //   .then((response) => {
    //     setAbout(response.data.bio);
    //     setLoadingRequest(false);
    //     setSuccessAbout("Bio has been updated succcessfully!");
    //   })
    //   .catch((error) => {
    //     setLoadingRequest(false);
    //     setError(true);
    //   });
  };

  const handleAvatar = (event) => {
    event.preventDefault();
    setLoadingRequest(true);

    const nftFormDataAvatar = new FormData();
    nftFormDataAvatar.append("avatar", newavatar);
    API.post(
      `api/${userAccount}/${sessionStorage.getItem(
        "secret"
      )}/user/modify/avatar/`,
      nftFormDataAvatar
    )
      .then(async (response) => {
        // setAvatar(response.data.avatar);
        try {
          const res = await axios.post("/api/updateuser", {
            address: userAccount.toLowerCase(),
            avatar: `https://app.nzvedazbigfive.io${response.data.avatar}`,
          });
          if (res.status === 202) {
            axios
              .get(`/api/signin?address=${userAccount}`)
              .then((useer) => {
                setAvatar(useer.data.avatar);
              })
              .catch((err) => {
                console.log("[err handle avatar]", err?.response, "----", err);
              });
          }
        } catch (err) {
          console.log("err [update user avatar]", err?.response);
        }
        setLoadingRequest(false);
        setSuccessAvatar("Avatar has been updated succcessfully!");
      })
      .catch((error) => {
        setLoadingRequest(false);
        setError(true);
      });
  };

  const handleCover = (event) => {
    event.preventDefault();
    setLoadingRequest(true);

    const nftFormDataCover = new FormData();
    nftFormDataCover.append("cover", newcover);
    API.post(
      `api/${userAccount}/${sessionStorage.getItem(
        "secret"
      )}/user/modify/cover/`,
      nftFormDataCover
    )
      .then(async (response) => {
        // setCover(response.data.cover);
        try {
          const res = await axios.post("/api/updateuser", {
            address: userAccount.toLowerCase(),
            cover: `https://app.nzvedazbigfive.io${response.data.cover}`,
          });
          if (res.status === 202) {
            axios
              .get(`/api/signin?address=${userAccount}`)
              .then((useer) => {
                setCover(useer?.data?.cover);
              })
              .catch((err) => {
                console.log("[err handle cover]", err?.response, "----", err);
              });
          }
        } catch (err) {
          console.log("err [update user avatar]", err?.response);
        }
        setLoadingRequest(false);
        setSuccessCover("Cover has been updated succcessfully!");
      })
      .catch((error) => {
        setLoadingRequest(false);
        setError(true);
      });
  };

  return (
    <div className="bft-main put-margin inner">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title} - Nzvedaz BFT Marketplace</title>
      </Helmet>
      {active ? (
        <div className="bft-section">
          {!loading ? (
            <>
              {!error ? (
                <>
                  <div className="bft-container">
                    <div className="bft-content bft-row-padding">
                      <div className="bft-third">
                        <div className="bft-card bft-padding bft-center">
                          {account?.toLowerCase() ===
                            userAccount?.toLowerCase() && (
                            <ModalPopup>
                              {(close) => (
                                <div className="bft-content">
                                  {!loadingRequest ? (
                                    <>
                                      <h4>
                                        UPDATE AVATAR{" "}
                                        <i
                                          className="fa fa-times pointer bft-right bft-large bft-text-red"
                                          onClick={close}
                                        ></i>
                                      </h4>
                                      <hr />
                                      <p className="bft-text-green bft-center">
                                        {successavatar}
                                      </p>
                                      <form onSubmit={handleAvatar}>
                                        <label htmlFor="avatar">
                                          Profile Picture
                                        </label>
                                        <input
                                          type="file"
                                          accept="image/*"
                                          id="avatar"
                                          className="bft-input"
                                          name="avatar"
                                          onChange={(e) =>
                                            setNewavatar(e.target.files[0])
                                          }
                                          required
                                        />
                                        <input
                                          type="submit"
                                          className="bft-input bft-btn bft-color"
                                          value="Update Avatar"
                                        />
                                      </form>
                                    </>
                                  ) : (
                                    <div className="bft-center">
                                      <h1>Updating avatar...</h1>
                                      <Loader />
                                    </div>
                                  )}
                                </div>
                              )}
                            </ModalPopup>
                          )}
                          {avatar ? (
                            <img
                              className="pointer bft-circle"
                              src={avatar}
                              alt=""
                              style={{ height: "100px", width: "100px" }}
                            />
                          ) : (
                            <img
                              className="pointer bft-circle"
                              src="/default/avatar.png"
                              alt=""
                              style={{ height: "100px", width: "100px" }}
                            />
                          )}
                        </div>
                        <br />

                        {account?.toLowerCase() ===
                          userAccount?.toLowerCase() && (
                          <Link to={"/account/create/"} className="link-dark">
                            <button
                              className="bft-btn"
                              style={{
                                width: "100%",
                                margin: "5px 0",
                                background: "#E36404",
                              }}
                            >
                              CREATE NFT ASSET
                            </button>
                          </Link>
                        )}

                        <Link
                          to={`/account/assets/${userAccount}`}
                          className="link-dark"
                        >
                          <button
                            className="bft-btn bft-sand"
                            style={{ width: "100%", margin: "5px 0" }}
                          >
                            {account?.toLowerCase() ===
                            userAccount?.toLowerCase()
                              ? "MANAGE ASSETS"
                              : "CHECK ASSETS"}
                          </button>
                        </Link>

                        <p>
                          Followers:{" "}
                          <span className="pointer bft-right bft-medium">
                            {followers}
                          </span>
                        </p>
                        <p>
                          Following:{" "}
                          <span className="pointer bft-right bft-medium">
                            {following}
                          </span>
                        </p>
                      </div>
                      <div className="bft-twothird">
                        <div className="bft-card bft-padding">
                          {account?.toLowerCase() ===
                            userAccount?.toLowerCase() && (
                            <Popup
                              modal={true}
                              trigger={
                                <i className="fa fa-edit pointer bft-right bft-large bft-padding"></i>
                              }
                              position="center"
                            >
                              {(close) => (
                                <div className="bft-content">
                                  {!loadingRequest ? (
                                    <>
                                      <h4>
                                        UPDATE COVER{" "}
                                        <i
                                          className="fa fa-times pointer bft-right bft-large bft-text-red"
                                          onClick={close}
                                        ></i>
                                      </h4>
                                      <hr />
                                      <p className="bft-text-green bft-center">
                                        {successcover}
                                      </p>
                                      <form onSubmit={handleCover}>
                                        <label htmlFor="cover">
                                          Profile Cover
                                        </label>
                                        <input
                                          type="file"
                                          accept="image/*"
                                          id="cover"
                                          className="bft-input"
                                          name="cover"
                                          onChange={(e) =>
                                            setNewcover(e.target.files[0])
                                          }
                                          required
                                        />
                                        <input
                                          type="submit"
                                          className="bft-input bft-btn bft-color"
                                          value="Update Cover"
                                        />
                                      </form>
                                    </>
                                  ) : (
                                    <div className="bft-center">
                                      <h1>Updating cover...</h1>
                                      <Loader />
                                    </div>
                                  )}
                                </div>
                              )}
                            </Popup>
                          )}
                          {cover ? (
                            <img
                              className="pointer"
                              src={cover}
                              alt=""
                              style={{ height: "250px", width: "100%" }}
                            />
                          ) : (
                            <img
                              className="pointer"
                              src="/default/cover.jpg"
                              alt=""
                              style={{ height: "250px", width: "100%" }}
                            />
                          )}
                          <br />
                          <br />
                          <h4>
                            Account Details
                            <i className="fa fa-info pointer bft-right bft-large"></i>
                          </h4>
                          <hr />
                          <div>
                            <p>Public Name: </p>
                            {name ? <> {name}</> : <> Artist Name</>}
                            {account?.toLowerCase() ===
                              userAccount?.toLowerCase() && (
                              <Popup
                                modal={true}
                                trigger={
                                  <i className="fa fa-edit pointer bft-right bft-large"></i>
                                }
                                position="center"
                              >
                                {(close) => (
                                  <div className="bft-content">
                                    {!loadingRequest ? (
                                      <>
                                        <h4>
                                          UPDATE NAME{" "}
                                          <i
                                            className="fa fa-times pointer bft-right bft-large bft-text-red"
                                            onClick={close}
                                          ></i>
                                        </h4>
                                        <hr />
                                        <p className="bft-text-green bft-center">
                                          {successname}
                                        </p>
                                        <form
                                          onSubmit={(e) => {
                                            handleName(e, close);
                                          }}
                                        >
                                          <label htmlFor="name">
                                            Public Name
                                          </label>
                                          <input
                                            type="text"
                                            id="name"
                                            className="bft-input"
                                            value={newname}
                                            onChange={(e) =>
                                              setNewname(e.target.value)
                                            }
                                            placeholder="Your name.."
                                            required
                                          />
                                          <input
                                            type="submit"
                                            className="bft-input bft-btn bft-color"
                                            value="Update Name"
                                          />
                                        </form>
                                      </>
                                    ) : (
                                      <div className="bft-center">
                                        <h1>Updating name...</h1>
                                        <Loader />
                                      </div>
                                    )}
                                  </div>
                                )}
                              </Popup>
                            )}
                          </div>
                          <p>
                            Address: {userAccount?.substring(0, 8)}...
                            {userAccount?.slice(-8)}
                            <i className="fa fa-copy pointer bft-right bft-large"></i>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="bft-container bft-content">
                    <div className="bft-card bft-padding">
                      <h4>About Me </h4>
                      {account?.toLowerCase() ===
                        userAccount?.toLowerCase() && (
                        <Popup
                          modal={true}
                          trigger={
                            <i className="fa fa-edit pointer bft-right bft-large"></i>
                          }
                          position="center"
                        >
                          {(close) => (
                            <div className="bft-content">
                              {!loadingRequest ? (
                                <>
                                  <h4>
                                    UPDATE BIO{" "}
                                    <i
                                      className="fa fa-times pointer bft-right bft-large bft-text-red"
                                      onClick={close}
                                    ></i>
                                  </h4>
                                  <hr />
                                  <p className="bft-text-green bft-center">
                                    {successabout}
                                  </p>
                                  <form
                                    onSubmit={(event) => {
                                      handleAbout(event, close);
                                    }}
                                  >
                                    <label htmlFor="about">About Me</label>
                                    <textarea
                                      id="about"
                                      className="bft-input"
                                      value={newabout}
                                      onChange={(e) =>
                                        setNewabout(e.target.value)
                                      }
                                      placeholder="Your bio.."
                                      required
                                    ></textarea>
                                    <input
                                      type="submit"
                                      className="bft-input bft-btn bft-color"
                                      value="Update Bio"
                                    />
                                  </form>
                                </>
                              ) : (
                                <div className="bft-center">
                                  <h1>Updating bio...</h1>
                                  <Loader />
                                </div>
                              )}
                            </div>
                          )}
                        </Popup>
                      )}

                      <hr />
                      <div>
                        <p>
                          {" "}
                          {about ? (
                            <> {about}</>
                          ) : (
                            <>
                              {" "}
                              This is your account description visible to the
                              world. Make it nice and straight-forward.
                            </>
                          )}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="bft-content bft-center">
                  <h1>Something went wrong, please check back later!</h1>
                  <img
                    src="/default/error.png"
                    alt="Error!"
                    style={{ height: "150px", width: "150px" }}
                  />
                </div>
              )}
            </>
          ) : (
            <div className="bft-container bft-content bft-center">
              <h1>Checking account information...</h1>
              <Loader />
            </div>
          )}
        </div>
      ) : (
        <div className="bft-section">
          <div className="bft-container">
            <div className="bft-content bft-center">
              <h1>
                You must be logged in with your wallet to access this page!
              </h1>
              <Popup
                modal={true}
                trigger={
                  <button className="bft-btn bft-green bft padding-large">
                    CONNECT YOUR WALLET
                  </button>
                }
                position="center"
              >
                <Wallet />
              </Popup>
            </div>
          </div>
        </div>
      )}
      <Top />
    </div>
  );
};

export default Account;
