import React, { useState, useEffect, useCallback } from "react";
import { useContract } from "./use-connector";

const useGetBalanceOf = ({ account }) => {
  const [balance, setBalance] = useState();
  const { tokenContract } = useContract();
  const getingUser = useCallback(async () => {
    if (tokenContract) {
      try {
        const blnce = await tokenContract.methods
          .balanceOf(account?.toLowerCase())
          .call();
        if (blnce === null || blnce === undefined) {
          setBalance("0");
        }
        setBalance(blnce);
      } catch (err) {
        console.log("err [useGetBalanceOf error]", err);
      }
    }
  }, [tokenContract, account]);
  useEffect(() => {
    getingUser();
  }, [getingUser, account]);

  return { balance };
};

export { useGetBalanceOf };
