import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useWeb3React } from "@web3-react/core";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import Wallet from "../sections/wallet";
import API from "../../jsx/backend";
import Loader from "../loader";
import Top from "../hooks/topping";
import uploadMedia from "../../utils/helpers/apis/pinata-upload-media";
import uploadJson from "../../utils/helpers/apis/pinata-upload-json";

import axios from "../hooks/axiosInstance";
import { useContract } from "../helpers/use-connector";
import { artifacts } from "../../contract-data/contract-data";

const Create = () => {
  const { chainId } = useWeb3React();
  const PINATA_BASE_URL_GATEWAY = "https://closedsea.mypinata.cloud/";
  // const [selectedfile, setSelectedfile] = useState(null);
  // const [contract, setContract] = useState({});

  const navigate = useNavigate();
  const { account, active } = useWeb3React();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [name, setName] = useState("");
  const [royalty, setRoyalty] = useState("");
  // const [price, setPrice] = useState("");
  const [image, setImage] = useState();
  const [description, setDescription] = useState("");
  const [creating, setCreating] = useState(true);
  const [asset, setAsset] = useState("");
  const [progress, setProgress] = useState();

  const { mediaContract, marketContract } = useContract();
  useEffect(() => {
    if (typeof account !== "undefined") {
      setLoading(true);
      API.get(`api/session/${account}/`)
        .then((response) => {
          const details = response.data;
          sessionStorage.setItem("secret", details.secret);
          setLoading(false);
        })
        .catch((error) => {
          setError(true);
          setLoading(false);
        });
    }
  }, [account]);

  // useEffect(() => {
  //   let { ethereum } = window;
  //   const web3Provider = new Web3(ethereum);
  //   // Media contract instance
  //   let contract = new web3Provider.eth.Contract(mediaContractABI, mediaContractAddress);
  //   setContract(contract);

  //   // Market contract instance
  //   let marketContract = new web3Provider.eth.Contract(marketContractABI, marketContractAddress);

  // }, []);

  /* const handleNew = (event) => {
    event.preventDefault();
    setProcessing(true);
    const nftFormDataNew = new FormData();
    nftFormDataNew.append("title", title);
    nftFormDataNew.append("description", description);
    // nftFormDataNew.append("price", price);
    API.post(
      `api/${account}/${sessionStorage.getItem("secret")}/assets/new/`,
      nftFormDataNew
    )
      .then((response) => {
        setCreating(false);
        setAsset(response.data.reference);
        setLoading(false);
        setProcessing(false);
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
        setProcessing(false);
      });
  }; */

  const handleFile = (e) => {
    try {
      setImage(e.target.files[0]);
    } catch (err) {
      console.log("[handleFile mint page] err ", err);
    }
  };

  // Function To Handle Mintin
  const handleMint = async (event) => {
    if (mediaContract && account) {
      try {
        setProgress();
        setLoading(true);
        event.preventDefault();
        let fileHash = await uploadMedia(image, setProgress);
        let mediaType = image?.type?.includes("pdf")
          ? "pdf"
          : image?.type?.split("/")[0];
        const metadata = {
          image: `${PINATA_BASE_URL_GATEWAY}ipfs/${fileHash}`,
          name: name,
          description: description,
          royalty: `${royalty}%`,
          mediaType: mediaType,
        };
        if (!fileHash) {
          console.log("Image Upload to IPFS Failed");
        }

        const json = {
          pinataMetadata: {
            name: `metadata-${name}`,
            keyvalues: {
              createdBy: account,
            },
          },
          pinataContent: metadata,
        };
        const jsonHash = await uploadJson(json);

        const resMetaData = await axios.get(
          `${PINATA_BASE_URL_GATEWAY}ipfs/${jsonHash}`
        );
        if (!jsonHash) {
          console.log("JSON Upload to IPFS Failed");
        }

        // mint function here
        let isFeePaid = await mediaContract.methods.feePaid(account).call();
        let oneTimeMintFee = await mediaContract.methods
          .oneTimeMintFee()
          .call();
        if (isFeePaid) {
          const res = await mediaContract.methods
            .mint(jsonHash, royalty * 100) // Royalty issue fixed here: 12August
            .send({ from: account, value: 0 });
          if (res.status) {
            resMetaData.data.tokenUri = `${PINATA_BASE_URL_GATEWAY}ipfs/${jsonHash}`;
            resMetaData.data.mediaType = mediaType;
            const uploadNFTtoMongo = await axios.post("/api/nft", {
              tokenId: res?.events?.Transfer?.returnValues?.tokenId,
              metadata: resMetaData?.data,
              tokenAddress: artifacts.mediaContractAddress[chainId],
              owner_of: res?.to,
              nftMinter: account?.toLowerCase(),
            });
            navigate(
              `/assets/${artifacts.mediaContractAddress[chainId]}/${res?.events?.Transfer?.returnValues?.tokenId}`
            );
          }
          setLoading(false);
        } else {
          const res = await mediaContract.methods
            .mint(jsonHash, royalty * 100) // Royalty issue fixed here: 12August
            .send({ from: account, value: oneTimeMintFee });
          if (res.status) {
            resMetaData.data.tokenUri = `${PINATA_BASE_URL_GATEWAY}ipfs/${jsonHash}`;
            const uploadNFTtoMongo = await axios.post("/api/nft", {
              tokenId: res?.events?.Transfer?.returnValues?.tokenId,
              metadata: resMetaData?.data,
              tokenAddress: artifacts.mediaContractAddress[chainId],
              nftMinter: account?.toLowerCase(),
            });
            navigate(
              `/assets/${artifacts.mediaContractAddress[chainId]}/${res?.events?.Transfer?.returnValues?.tokenId}`
            );
          }
          setLoading(false);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);

        console.log("err", err);
      }
    } else console.log("account and contract not found");
  };

  return (
    <div className="bft-main put-margin inner">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Create NFT Asset - Nzvedaz BFT Marketplace</title>
      </Helmet>
      {active ? (
        <div className="bft-section">
          {!loading ? (
            <>
              {!error ? (
                <div className="bft-container">
                  {!processing ? (
                    <>
                      {creating ? (
                        <div className="bft-content">
                          <h4>
                            Create New NFT
                            <i className="fa fa-edit pointer bft-right bft-large"></i>
                          </h4>
                          <hr />
                          <br />
                          <div>
                            {image?.type?.split("/")[0] === "image" ? (
                              <img
                                className="bft-nft-media"
                                src={URL.createObjectURL(image)}
                                alt="img"
                              />
                            ) : image?.type?.split("/")[0] === "video" ? (
                              <video className="bft-nft-media" controls>
                                <source src={URL.createObjectURL(image)} />
                              </video>
                            ) : null}
                          </div>
                          <input type="file" onChange={(e) => handleFile(e)} />
                          <br />
                          <form onSubmit={handleMint}>
                            <br />
                            <label htmlFor="name">Name</label>
                            <input
                              type="text"
                              id="name"
                              className="bft-input"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              placeholder="Your new NFT Name..."
                              required
                            />
                            {/* <br /> */}

                            {/* <br />
                            <label htmlFor="price">Price (BFT)</label>
                            <input
                              type="number"
                              step=".01"
                              min="0"
                              id="price"
                              className="bft-input"
                              value={price}
                              onChange={(e) => setPrice(e.target.value)}
                              placeholder="Your new NFT price..."
                              required
                            /> */}

                            <br />
                            <label htmlFor="description">Description</label>
                            <textarea
                              id="description"
                              className="bft-input"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              placeholder="Your new NFT description..."
                              required
                            ></textarea>

                            <label htmlFor="royalty">Royalty</label>
                            <input
                              type="number"
                              id="royalty"
                              className="bft-input"
                              // value={royalty <= 10 ? royalty : 10} // changes 27/Sep/22
                              value={royalty}
                              onChange={(e) => setRoyalty(e.target.value)}
                              placeholder="NFT Royalty in % i.e. 1-10"
                            />

                            <br />
                            <input
                              type="submit"
                              value="CREATE NEW NFT ASSET"
                              className="bft-input bft-color bft-btn"
                            />
                            <p>
                              You will be able to upload files at the next
                              stage. All created assets will be added to your
                              account under assets but will not appear on the
                              platform until you finish minting. If you wish to
                              make changes to the content created here, you can
                              do so by managing your asset later.
                            </p>
                          </form>
                        </div>
                      ) : (
                        <div className="bft-content">
                          <h4>
                            Manage New NFT Asset
                            <i className="fa fa-check-circle pointer bft-right bft-large bft-text-green"></i>
                          </h4>
                          <hr />
                          <p>
                            <b>Name:</b> {name}
                          </p>

                          <p>
                            <b>Description:</b> {description}
                          </p>
                          <br />
                          <p>
                            <b>NB:</b> You must finish uploading your NFT and
                            proceed to minting before it can appear on our
                            platform. You can do this process at any time, we
                            save your NFT under Assets in your account.
                          </p>
                          <br />

                          {/* <Link to={`/account/assets/${asset}`}> */}
                          <button
                            className="bft-btn bft-pale-green"
                            style={{ width: "100%" }}
                          >
                            START MANAGING ASSET
                          </button>
                          {/* </Link> */}
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="bft-content bft-center">
                      <h1>Creating new asset...</h1>
                      <Loader />
                    </div>
                  )}
                </div>
              ) : (
                <div className="bft-content bft-center">
                  <h1>Something went wrong, please check back later!</h1>
                  <img
                    src="/default/error.png"
                    alt="Error!"
                    style={{ height: "150px", width: "150px" }}
                  />
                </div>
              )}
            </>
          ) : (
            <div className="bft-container bft-content bft-center">
              <h1>Checking account information...</h1>
              {progress !== undefined && progress !== 0 && progress !== 100 && (
                <h3>
                  Uploading File progress: <strong>{progress}</strong>
                </h3>
              )}
              <Loader />
            </div>
          )}
        </div>
      ) : (
        <div className="bft-section">
          <div className="bft-container">
            <div className="bft-content bft-center">
              <h1>
                You must be logged in with your wallet to access this page!
              </h1>
              <Popup
                modal={true}
                trigger={
                  <button className="bft-btn bft-green bft padding-large">
                    CONNECT YOUR WALLET
                  </button>
                }
                position="center"
              >
                <Wallet />
              </Popup>
            </div>
          </div>
        </div>
      )}
      <Top />
    </div>
  );
};

export default Create;
