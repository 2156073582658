import { useWeb3React } from "@web3-react/core";
import toast from "react-hot-toast";
import React, { useState } from "react";
import Popup from "reactjs-popup";
import Web3 from "web3";
import { artifacts } from "../../../contract-data/contract-data";
import { useContract } from "../../helpers/use-connector";
import Loader from "../../loader";
import axios from "../../hooks/axiosInstance";
import { useEffect } from "react";

const PublishAssetModal = ({ tokenId, getAssetData }) => {
  const [isApprovedForAll, setIsApprovalForAll] = useState();

  const [price, setPrice] = useState();
  const [loading, setLoading] = useState(false);
  const { account,chainId } = useWeb3React();
  const { mediaContract } = useContract();

  useEffect(() => {

    async function checkApproval() {
      let isApprovedForAll = await mediaContract.methods.isApprovedForAll(account, artifacts.mediaContractAddress).call();
      setIsApprovalForAll(isApprovedForAll);
    }
    if (mediaContract) {
      checkApproval();
    }

  }, [account])

  const handlePublishingAsset = async (e, close) => {
    try {
      e.preventDefault();
      setLoading(true);
      const resTrans = await mediaContract.methods
        .setAsk(tokenId, [
          Web3.utils.toWei(String(price), "ether"),
          artifacts.tokenContractAddress[chainId],
        ])
        .send({
          from: account,
        });
      if (resTrans?.status === true) {
        const res = await axios.post("/api/nft", {
          tokenId,
          price: Web3.utils.toWei(String(price), "ether"),
          isOnSell: true,
        });
        toast.success("Published Successfully...");
        getAssetData();
      } else {
        toast.error("Transaction Failed...");
      }
      setLoading(false);
      close();
    } catch (err) {
      if (err?.code === 4001) {
        toast.error(err.message);
      } else {
        toast.error("something went wrong...");
        console.log("err [handlePublishingAsset] ", err);
      }
      setLoading(false);
      close();
    }
  };
  const handleApproveAsset = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      let trx = await mediaContract.methods.setApprovalForAll(artifacts.mediaContractAddress[chainId], true).send({
        from: account
      });
      if (trx.status == true) {
        setIsApprovalForAll(true);
      }
      else setIsApprovalForAll(false);
      setLoading(false);

    } catch (e) { console.log("err [handleApproveAsset] ",e); }
  }

  return (
    <Popup
      modal={true}
      trigger={
        <button
          className="bft-btn bft-color"
          style={{ flexGrow: 1, color: "#fff" }}
        >
          Publish Asset
        </button>
      }
      position="center"
    >
      {(close) => (
        <div className="bft-content">
          {!loading ? (
            <>
              <h4>
                Publish Asset
                <i
                  className="fa fa-times pointer bft-right bft-large bft-text-red"
                  onClick={close}
                ></i>
              </h4>
              <hr />
              {
                isApprovedForAll ?
                  <form
                    onSubmit={(e) => {
                      handlePublishingAsset(e, close);
                    }}
                  >
                    <label htmlFor="cover">Price</label>
                    <input
                      type="number"
                      id="price"
                      className="bft-input"
                      placeholder="Enter Price"
                      name="price"
                      onChange={(e) => setPrice(e.target.value)}
                      style={{ border: "1px solid gray", marginBottom: "10px" }}
                      required
                    />
                    <input
                      type="submit"
                      className="bft-input bft-btn bft-color"
                      value="Publish"
                    />
                  </form> :
                  <form
                    onSubmit={(e) => {
                      handleApproveAsset(e);
                    }}
                  >
                    <input
                      type="submit"
                      className="bft-input bft-btn bft-color"
                      value="Approve Asset"
                    />
                  </form>
              }

            </>
          ) : (
            <div className="bft-center">
              <h1>Waiting for Transaction...</h1>
              <Loader />
            </div>
          )}
        </div>
      )}
    </Popup>
  );
};

export default PublishAssetModal;
