import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Web3 from "web3";
import { getEllips } from "../../helpers/common";
import { useGetBid } from "../../helpers/use-get-bid";
import axios from "../../hooks/axiosInstance";
import AcceptBid from "../AcceptBid";
import RemoveBid from "../RemoveBid";
const BidsTables = ({ owner, getAssetData }) => {
  const { tokenId } = useParams();
  const { bids } = useGetBid({ tokenId });
  const { account } = useWeb3React();
  const [loading, setLoading] = useState();
  const naviagte = useNavigate();

  return (
    <table
      className="bft-nft-table"
      style={{ width: "100%", minWidth: "560px" }}
    >
      <tbody>
        <tr>
          <th>Price</th>
          <th style={{ width: "30%" }}>From</th>
          <th>Address</th>
        </tr>
        {bids?.length > 0 &&
          bids.map((v, i) => (
            <tr key={"ias" + i}>
              <td>{Web3.utils.fromWei(v?.amount, "ether")} bft</td>
              <td
                onClick={() => {
                  naviagte(`/account/${v?.bidder}`);
                }}
                className="bidtable-td"
              >
                {v?.user?.avatar && (
                  <img
                    src={v?.user?.avatar}
                    width={40}
                    height={40}
                    style={{ borderRadius: "50%" }}
                    alt="img"
                  />
                )}{" "}
                {v?.user?.name ? v.user.name : "user-000"}
              </td>
              <td>{getEllips(v?.bidder)}</td>
              {account?.toLowerCase() === owner?.toLowerCase() && (
                <td>
                  <AcceptBid getAssetData={getAssetData} bidder={v?.bidder} />
                </td>
              )}
              {v?.bidder?.toLowerCase() === account?.toLowerCase() && (
                <td>
                  <RemoveBid getAssetData={getAssetData} />
                </td>
              )}
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default BidsTables;
