import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

function Loader() {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: #d85606;
  `;

  return <ClipLoader css={override} size={150} />;
}

export default Loader;
