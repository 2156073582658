import {
  artifacts,
  mediaContractABI
} from "../../contract-data/contract-data";
import React, { useCallback } from "react";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";


export function getEllips(str) {
  if (str) {
    return `${str.slice(0, 6)}...${str.slice(-12)}`;
  }
  return "";
}
export function useContract() {
  const {chainId} = useWeb3React();
  let { ethereum } = window;
  const web3Provider = new Web3(ethereum);
  let contract = new web3Provider.eth.Contract(mediaContractABI, artifacts.mediaContractAddress[chainId]);
  return contract;
}
