import axios from "axios";

const PINATA_BASE_URL = "https://api.pinata.cloud/";
const PINATA_BASE_URL_GATEWAY = "https://closedsea.mypinata.cloud/";
const PINATA_BASE_URL_FREE = "https://gateway.pinata.cloud/";

const PINATA_API_KEY = "c027b0a0eebda0cf01e4";
const PINATA_SECRET_API_KEY =
  "52b7362f9e862f879aad5d87121a018a262ffb1346ef6f67be955f92fe55ac03";

const uploadJson = async (jsonData) => {
  const url = `${PINATA_BASE_URL}pinning/pinJSONToIPFS`;

  return axios
    .post(url, jsonData, {
      headers: {
        pinata_api_key: PINATA_API_KEY,
        pinata_secret_api_key: PINATA_SECRET_API_KEY,
      },
    })
    .then((response) => {
      const y = response.data.IpfsHash;
      return y;
    })
    .catch((error) => {
      console.log("[uploadMedia] error => ", error);
      return null;
    });
};

export default uploadJson;
