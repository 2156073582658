import React, { useState } from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 80) {
      setVisible(true);
    } else if (scrolled <= 80) {
      setVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  window.addEventListener("scroll", toggleVisible);

  return (
    <div className="bft-main put-margin">
      <br />
      <footer className="bft-container bft-color bft-center foot">
        <div className="bft-content bft-row-padding">
          <div className="bft-col s6">
            <div className="bft-third">
              <Link to={"/"} className="link-dark">
                Home
              </Link>
            </div>
            <div className="bft-third">
              <Link to={"/privacy/"} className="link-dark">
                Privacy
              </Link>
            </div>
            <div className="bft-third">
              <Link to={"/terms/"} className="link-dark">
                Terms
              </Link>
            </div>
          </div>
          <div className="bft-col s6">
            <div className="bft-third">
              <a
                href="https://www.facebook.com/thebigfivetoken/"
                target="_blank"
                rel="noreferrer"
                className="link-dark"
              >
                Facebook
              </a>
            </div>
            <div className="bft-third">
              <a
                href="https://t.me/thebigfivetokenchat/"
                target="_blank"
                rel="noreferrer"
                className="link-dark"
              >
                Telegram
              </a>
            </div>
            <div className="bft-third">
              <a
                href="https://twitter.com/five_token/"
                target="_blank"
                rel="noreferrer"
                className="link-dark"
              >
                Twitter
              </a>
            </div>
          </div>
        </div>
        <br />
        <p>
          &copy;2021 - {new Date().getFullYear()} Nzvedaz Big Five Token. All
          Rights Reserved
        </p>
        <button
          onClick={scrollToTop}
          style={{ display: visible ? "inline" : "none" }}
          className="bft-scroll-up bft-btn bft-color"
          title="Scroll Up"
        >
          <i className="fas fa-arrow-up"></i>
        </button>
      </footer>
    </div>
  );
};

export default Footer;
