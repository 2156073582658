import { useWeb3React } from "@web3-react/core";
import toast from "react-hot-toast";
import React, { useState } from "react";
import Popup from "reactjs-popup";
import Web3 from "web3";
import { tokenContract } from "../../../contract-data/contract-data";
import { useContract } from "../../helpers/use-connector";
import Loader from "../../loader";
import axios from "../../hooks/axiosInstance";

const UnPublishModal = ({ tokenId, getAssetData }) => {
  const [price, setPrice] = useState();
  const [loading, setLoading] = useState(false);
  const { account } = useWeb3React();
  const { mediaContract, marketContract } = useContract();

  const handleUnPublishingAsset = async (e, close) => {
    try {
      e.preventDefault();
      setLoading(true);
      const resTrans = await mediaContract.methods
        .removeAsk(tokenId)
        .send({ from: account });

      if (resTrans?.status === true) {
        const res = await axios.post("/api/nft", {
          tokenId,
          price: 0,
          isOnSell: false,
        });
        toast.success("Un-Published Successfully...");
        getAssetData();
      } else {
        toast.error("Transaction Failed...");
      }
      setLoading(false);
      close();
    } catch (err) {
      if (err?.code === 4001) {
        toast.error(err.message);
      } else {
        toast.error("something went wrong...");
        console.log("err [handlePublishingAsset] ", err);
      }
      setLoading(false);
      close();
    }
  };
  return (
    <Popup
      modal={true}
      trigger={
        <button
          className="bft-btn bft-color"
          style={{ flexGrow: 1, color: "#fff" }}
        >
          Un-Publish Asset
        </button>
      }
      position="center"
    >
      {(close) => (
        <div className="bft-content">
          {!loading ? (
            <>
              <h4>
                Un-Publish Asset
                <i
                  className="fa fa-times pointer bft-right bft-large bft-text-red"
                  onClick={close}
                ></i>
              </h4>
              <hr />
              <form
                onSubmit={(e) => {
                  handleUnPublishingAsset(e, close);
                }}
                style={{ display: "flex", gap: "5px" }}
              >
                <button className="bft-input bft-btn bft-color" onClick={close}>
                  Close
                </button>
                <input
                  type="submit"
                  className="bft-input bft-btn bft-color"
                  value="Un-Publish"
                />
              </form>
            </>
          ) : (
            <div className="bft-center">
              <h1>Waiting for Transaction...</h1>
              <Loader />
            </div>
          )}
        </div>
      )}
    </Popup>
  );
};

export default UnPublishModal;
