import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import {
  artifacts,
  mediaContractABI,
} from "../contract-data/contract-data";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";


export const injected = new InjectedConnector({
  supportedChainIds: [56, 97],
});

export const walletconnect = new WalletConnectConnector({
  supportedChainIds: [1, 4, 100],
  qrcode: true,
});

export const useConnector = () => {
  let { ethereum } = window;
  const {chainId} = useWeb3React();
  const web3Provider = new Web3(ethereum);
  let contract = new web3Provider.eth.Contract(
    mediaContractABI,
    artifacts.mediaContractAddress[chainId]
  );
  return contract;
};
