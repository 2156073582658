import React, { useState, useEffect, useCallback } from "react";
import axios from "../hooks/axiosInstance";
import { useContract } from "./use-connector";

const useGetPrice = ({ tokenId, nftData }) => {
  const [priceObj, setPriceObj] = useState();
  const { marketContract } = useContract();
  const getingUser = useCallback(async () => {
    if (marketContract) {
      try {
        const rescontract = await marketContract.methods
          .currentAskForToken(tokenId)
          .call();
        setPriceObj(rescontract);
      } catch (err) {
        console.log("err [fetching marketContract error]", err);
      }
    }
  }, [tokenId, marketContract, nftData]);
  useEffect(() => {
    getingUser();
  }, [getingUser, tokenId, nftData]);

  return { price: priceObj };
};

export { useGetPrice };
